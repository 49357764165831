import React, { useEffect } from 'react';
import '../assets/fonts/Poppins/Poppins-Light.ttf';
import '../assets/fonts/Poppins/Poppins-Bold.ttf';
import '../assets/fonts/Poppins/Poppins-Regular.ttf';
import "../components/layout.css";
import Combo from '../components/Ebooks/Combo';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';


// import React from "react"
// import SEO from "../components/seo"
// import PageEbooks from "../PageEbooks"
// import '../assets/fonts/Poppins/Poppins-Light.ttf';
// import '../assets/fonts/Poppins/Poppins-Bold.ttf';
// import '../assets/fonts/Poppins/Poppins-Regular.ttf';
// import "../components/layout.css";
// import { URL_HOTMART_COMBO_EBOOK } from "../constants";

// const Ebooks = () => {
//   return(
//     <>
//       <SEO title="Guia completo sobre hábitos e metas"  pageId="ebook_habitos_e_metas"/>
//       <PageEbooks
//         title="GUIA COMPLETO SOBRE HÁBITOS E METAS!"
//         subTitle="APRENDA COMO CRIAR BONS HÁBITOS, REALIZAR PLANEJAMENTOS E ATINGIR SUAS METAS!"
//         urlCheckout={URL_HOTMART_COMBO_EBOOK}
//         textButton="OBTER OS 2 EBOOKS! COM 30% OFF"
//         price="R$ 34,70"
//         call={<>OFERTA IMPERDÍVEL (30% OFF) + 5 BÔNUS:<br/> 
//           de <s>R$ 49,40</s> <span>por apenas</span> R$ 34,70</>}
//         bonus={
//           [
//             {
//               name: 'Guia estratégico para criação de hábitos',
//               img: <img alt="bonus" src={require("../assets/images/bonus2.png")} style={{height:250,width:350,margin:10,marginTop:60}} />,
//               description: 'Passo a passo para definir diversas estratégias para criação de seus hábitos',
//             },
//             {
//               name: 'Rastreador de hábitos',
//               img: <img alt="bonus" src={require("../assets/images/bonus1.png")} style={{height:250,width:350,marginTop:60}} />,
//               description: 'Monitore o andamento de seus hábitos mês a mês e veja seu progresso'
//             },
//             {
//               name: 'Guia estratégico para criação de metas',
//               img: <img alt="bonus" src={require("../assets/images/bonus5.png")} style={{height:250,width:350,margin:10,marginTop:60}} />,
//               description: 'Passo a passo para definir metas de forma eficiente'
//             },
//             {
//               name: 'Planejamento anual',
//               img: <img alt="bonus" src={require("../assets/images/bonus3.png")} style={{height:250,width:350,marginTop:60}} />,
//               description: 'Planeje o seu ano de forma eficiênte com as dicas do ebook'
//             },
//             {
//               name: 'Roda da Vida',
//               img: <img alt="bonus" src={require("../assets/images/bonus4.png")} style={{height:250,width:350,marginTop:60}} />,
//               description: 'Reflexão sobre todas areas de sua vida, para a partir disso definir suas metas e planejamento.'
//             },
//           ]
//         }
//       />
//     </>
// )}
// export default Ebooks


const GuiaDefinitivoParaProdutividade = () => {
  useEffect(()=>{
    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: "Ebook",
      // string - required - Type of interaction (e.g. 'play')
      action: "View Page Ebook",
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label: "view-combo",
      // number - optional - Numeric value associated with the event. (e.g. A product ID)
      value: 'combo'
    })
  },[])
  return <Combo/>
}
export default GuiaDefinitivoParaProdutividade